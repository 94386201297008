import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Headline from '../../components/Headline';
import Layout from '../../components/Layout';
import SEO, { createAlternateLinks } from '../../components/Seo';
import Termly from '../../components/Termly';

const messages = defineMessages({
  title: {
    id: 'termsOfService.title',
    defaultMessage: 'Terms Of Service',
  },
});

const StaticPage = ({
  location: { pathname },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const alternateLinks = createAlternateLinks(originalPath);

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        pathname={pathname}
        link={alternateLinks}
        robots="noindex, nofollow"
      />
      <Layout originalPath={originalPath}>
        <Headline title={formatMessage(messages.title)} />
        <Termly dataId="d038d6a3-bbc6-458e-8070-f466cbf56142" />
      </Layout>
    </>
  );
};

StaticPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default StaticPage;
