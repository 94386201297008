/* eslint-disable react/jsx-no-target-blank */

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

function mountTermlyScript() {
  document.getElementById('termly-jssdk')?.remove();
  const scriptTag = document.createElement('script');

  scriptTag.src = '//app.termly.io/embed-policy.min.js';
  scriptTag.async = true;
  scriptTag.type = 'text/javascript';
  scriptTag.id = 'termly-jssdk';

  scriptTag.onerror = (errs) => console.error(errs);

  document.body.appendChild(scriptTag);
}

const Termly = ({ dataId }) => {
  useEffect(() => {
    if (window) {
      mountTermlyScript();
    }
  }, []);
  return (
    <>
      <Helmet
        link={[
          {
            rel: 'dns-prefetch',
            href: '//app.termly.io',
          },
        ]}
      />
      <div
        id="termly-embed"
        key="termly-embed"
        name="termly-embed"
        data-id={dataId}
        data-type="iframe"
      />
    </>
  );
};

Termly.propTypes = {
  dataId: PropTypes.string.isRequired,
};

export default Termly;
