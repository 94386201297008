import React from 'react';
import PropTypes from 'prop-types';

import * as s from './Headline.module.css';

const Headline = ({ title }) => (
  <header className={s.root}>
    <h1 className={s.title}>{title}</h1>
    <div className={s.fold} />
  </header>
);

Headline.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Headline;
